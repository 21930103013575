import { getPromoTags } from './promo-tags.js';

export function loadMoreProducts() {
  // counts elements
  let productsOnPage = document.querySelector('.js-products-on-page');
  let productCountElement = document.querySelector('.js-product-loaded-count');
  let totalProductCountElement = document.querySelector('.product-total-count');
  let totalProductCountInt = parseInt(
    totalProductCountElement?.dataset?.totalProducts
  );

  // init loaded product count
  let initialProductCount = parseInt(productsOnPage?.dataset?.paginationSize);
  let loadedProductCount = initialProductCount;

  // button / spinner / progress bar
  const loadMoreBtn = document.querySelector('.js-load-more-collection__btn');
  const loadMoreSpinner = document.querySelector('.load-more__spinner');
  const progressBar = document.querySelector('#search-pagination-progress-bar');
  const collectionName = productsOnPage?.dataset?.collectionName;

  if (!loadMoreBtn || !loadMoreSpinner) return;

  async function fetchNextPageProducts() {
    try {
      // hide the load more button
      loadMoreBtn.style.display = 'none';
      // show the spinner
      loadMoreSpinner.style.display = 'block';
      // get the next page
      let nextUrl = productsOnPage?.dataset?.nextUrl;

      const response = await fetch(nextUrl);
      const next_page = await response.text();

      let parser = new DOMParser();
      let doc = parser.parseFromString(next_page, 'text/html');
      let newProducts = doc.querySelector('.js-products-on-page');

      // pagination length updates product count
      if (newProducts.dataset.nextUrl) {
        loadedProductCount += initialProductCount;
      } else {
        loadedProductCount = totalProductCountInt;
        // remove the load more button listener
        loadMoreBtn.removeEventListener('click', fetchNextPageProducts);
        dataLayer.push({
          vendor: collectionName,
          event: 'reached last page of collection'
        });
      }

      // update the DOM count element
      productCountElement.textContent = loadedProductCount;

      // swap the nextUrl
      productsOnPage.dataset.nextUrl = newProducts.dataset.nextUrl;

      // append products to the page
      productsOnPage.insertAdjacentHTML('beforeend', newProducts.innerHTML);

      // hide the spinner
      loadMoreSpinner.style.display = 'none';

      // update the progress bar if on a collection or search page
      if (
        window.location.href.includes('collections') ||
        window.location.href.includes('search')
      ) {
        progressBar.style.width =
          (loadedProductCount / totalProductCountInt) * 100 + '%';
      }

      // add promo tags to the fetched product cards
      getPromoTags();

      // toggle the load more button display
      toggleLoadMoreBtnDisplay();
    } catch (error) {
      console.error('Error loading more products:', error);
    }
  }

  function toggleLoadMoreBtnDisplay() {
    if (productsOnPage.dataset.nextUrl) {
      loadMoreBtn.style.display = 'block';
    } else {
      loadMoreBtn.style.display = 'none';
    }
  }

  // Initial setup of the load more button listener
  loadMoreBtn?.addEventListener('click', fetchNextPageProducts);
}

document.addEventListener('DOMContentLoaded', loadMoreProducts);
